<template>
    <a-layout>
        <a-layout-header class="header">
            <div class="tab">
                <div class="tab-left">
                    <a href="https://qunkanban.maoxiubiji.com">
                        <img class="logo" src="../assets/img/header_logo.png" alt="猫嗅群看板" title="猫嗅群看板" />
                    </a>
                </div>
            </div>
        </a-layout-header>
        <a-layout-content style="background-color: #fff;margin-top: 60px;">
            <router-view />
        </a-layout-content>
        <a-layout-footer class="footer">
            <div class="footer-3">
                    <view class="xieyi">
                        <router-link target="_blank" to="/service_agreement">服务协议</router-link>
                        <router-link style="margin-left: 20px;" target="_blank" to="/privacy_agreement">隐私政策</router-link>
                    </view>
                    <p class="records">Copyright © 2016-{{$moment().format('YYYY')}} 义乌市猫嗅网络科技有限公司 All Rights Reserved 
                        <a style="color: #fff;margin-left: 8px;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16010149号-8</a> 
                        <a style="display: flex;align-items: center;color: #fff;margin-left: 8px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078202002117" target="_blank"><img style="width: 20px;height: 20px;margin-right: 4px;" src="../assets/img/beianIcon.png" />浙公网安备 33078202002117号</a>
                    </p>
                </div>
        </a-layout-footer>
    </a-layout>
</template>
<script>
export default {
    name: 'BasicLayout',
    setup() {
        return {

        }
    }
}
</script>
<style lang="less" scoped>
.header {
    position: fixed;
    height: 60px;
    line-height: 60px;
    box-shadow: 0 2px 4px -4px rgba(31,35,41,.02), 0 4px 8px rgba(31,35,41,.02), 0 4px 16px 4px rgba(31,35,41,.03);
    background-color: #fff;
    padding:0;
    z-index: 999;
    width: 100%;
    .tab {
        width: 1200px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;

        .tab-left {
            display: flex;
            align-items: center;

            .logo {
                height: 28px;
            }
        }
    }
}


.footer{
    background: #001529;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    .footer-3 {
        background-color: #001529;
        height: 50px;
        text-align: center;
        .xieyi{
            a {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
                margin-bottom: 15px;
                text-decoration: none;
            }
        }
        .records {
            display: flex;
            align-items: center;
            margin: 8px 0 0;
            font-size: 12px;
            color: #fff;
            white-space: nowrap;
        }

    }
}
</style>