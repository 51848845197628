<template>
    <a-config-provider :theme="theme" :locale="locale">
        <router-view />
    </a-config-provider>
</template>
<script>
import { defineComponent } from 'vue';
import defaultSettings from '@/config/defaultSettings';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
export default defineComponent({
    name: 'App',
    setup() {
        const theme = {
            token: {
                colorPrimary: defaultSettings.colorPrimary,
            },
        }

        return {
            theme,
            locale: zhCN,
        }
    }
})
</script>