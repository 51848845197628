import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue';
import 'ant-design-vue/dist/reset.css';

import router from './router';


const app = createApp(App);

import moment from "moment"; 
app.config.globalProperties.$moment = moment;

app.use(Antd).use(router).mount('#app');
