
import BasicLayout from '@/layouts/BasicLayout';
//路由配置
export const constantRouterMap = [
    {
        path: '/', 
        redirect: '/',
        component: BasicLayout,  
        children: [
            {
                path: '/',
                meta:{
                    title:'首页'
                },
                component: () => import('@/pages/index/Index')
            },
        ]
    },
    {
        path: '/service_agreement',
        meta:{
            title:'服务协议'
        },
        component: () => import('@/pages/agreement/ServiceAgreement')
    },
    {
        path: '/privacy_agreement',
        meta:{
            title:'隐私政策'
        },
        component: () => import('@/pages/agreement/PrivacyAgreement')
    },
];